//  ********* Media Queries For Desktop  ************

$for-desktop: 75em;

$xl-desktop: 90.625rem;

@mixin xlDesktop {
  @media screen and (min-width: #{ $xl-desktop }) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: #{ $for-desktop }) {
    @content;
  }
}

$for-laptop: 62.5em;

@mixin laptop {
  @media screen and (min-width: #{ $for-laptop }) {
    @content;
  }
}

//  ********* Media Queries For Tab  ************

$tab: 991px;

@mixin tab {
  @media screen and (max-width: #{ $tab }) {
    @content;
  }
}

//  ********* Media Queries For Mobile  ************

$mobile: 767px;

@mixin mobile {
  @media screen and (max-width: #{ $mobile }) {
    @content;
  }
}

$sm-mobile: 400px;

@mixin sm-mobile {
  @media screen and (max-width: #{ $mobile }) {
    @content;
  }
}

//min width
@mixin msm-mobile {
  @media screen and (min-width: #{ $mobile }) {
    @content;
  }
}

$min-tab-width: 47.5em;
$max-tab-width: 60em;

@mixin tablet {
  @media screen and (min-width: #{$min-tab-width}) and (max-width: #{$max-tab-width}) {
    @content;
  }
}

//min max media queries
$min-mob-width: 17em;
$max-mob-width: 40em;
@mixin mmMob() {
  @media screen and (min-width: #{$min-mob-width}) and (max-width: #{$max-mob-width}) {
    @content;
  }
}

$min-tab-width: 40em;
$max-tab-width: 47.5em;
@mixin mmTab() {
  @media screen and (min-width: #{$min-tab-width}) and (max-width: #{$max-tab-width}) {
    @content;
  }
}

$min-TabL-width: 47.5em;
$max-TabL-width: 62.5em;
@mixin mmTabL() {
  @media screen and (min-width: #{$min-TabL-width}) and (max-width: #{$max-TabL-width}) {
    @content;
  }
}
