@import 'variables';

.solidButton,
.outlineButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.solidButton {
  background: $primary-color;
  color: #fff;
  border: none;

  .p-ink {
    background-color: $primary-color-200;
  }

  &:disabled {
    background: lighten($primary-color, 15%);

    .p-ink {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  & > .loader {
    display: block;
    margin-left: 0 !important;
    height: 1.5625rem !important;
    width: 1.5625rem !important;
  }
}

.outlineButton {
  border: 1px solid $primary-color;
  background: transparent;
  color: $primary-color;

  &:hover {
    background: rgba($primary-color, 0.1);
  }

  &:disabled {
    color: lighten($primary-color, 15%);
    border-color: lighten($primary-color, 15%);
    cursor: not-allowed;
  }

  .p-ink {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.iconOnly {
  padding: 0.5rem;
  gap: 0;
}
