// Variable overrides
$primary-bg: #f9f9f9;
$themecolordarkgreen: #1c672e;
$themecolorgreen: #2ea549;
$white: #ffffff;
// $themecolorblue: #19458c;
$light: #f2f2f2;
$greytext: #5a5b5e;
$bordercolor: #e7e6e6;
$primary-color: #bb9356;
$primary-nav-color: #000;
$border-color: $primary-color;
$disabled-color: #785f3a;
$primary-color-200: #fff4e2de;
$secondary-color: #fae0a8;
$primary-light: #fff5e7;
$room-Info-Header: #ffefd9;
$top-nav-color: #000;
$primary-star-color: #ffaf4a;
$hover-color: #1c1001;
$postTicketing-bg: #fffbf5;
// default value variables for styles
$border-r: 0.8rem;
$px: 2rem;
$py: 1.2rem;
$section-bottom-gap: 5rem;
$mobile-margin-b: 3rem;
$heading-ml: 1rem;
$padding-section-top: 3rem;
$padding-section-b: 3rem;
$defaultPriceSize: 1rem;
$childAgeLabelFs: 0.8rem;
$box-shadow-default: 0rem 0.25rem 0.625rem 0rem #0000000a;
$searchResultPT: 1.5rem;
$defaultRadius: 0.3125rem;
$containerPaddingTab: 0.75rem;
$smPaddingX: 1rem;

//responsive variables
$tabMWidth: 53.125rem;

//b2b variables
$loginPagePadding: 1.5rem;

$subtitle-texts: #000; // when need to differentiate between heading and subheading change to #838383 and in why book us please replace #1e1e1e80
