@import './assets/variables/responsive_variables.scss';
@import 'scss/_rtl';

// PrimeNG styles
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

/* You can add global styles to this file, and also import other style files */
@import 'scss/_variables';
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import '~ngx-toastr/toastr';

/* Importing DatePicker SCSS file. */

@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&display=swap');

// button
@import './scss/button';
@import './scss/animation';

* {
  box-sizing: border-box;
}

html {
  font-family: 'Mulish', sans-serif !important;
  scroll-behavior: smooth;
}

body {
  font-family: 'Mulish', sans-serif !important;
  overflow-x: hidden;
  background-color: $primary-bg !important;
  margin: 0;
  padding: 0;
}

.card,
.custom-card {
  box-shadow: $box-shadow-default !important;
}

.p-rating-icon.pi-star-fill {
  color: $primary-star-color !important;
}

.perPersonPrice {
  font-size: 0.7rem !important;
  line-height: 0.6rem !important;
  /* color: #777 !important; */
  color: $subtitle-texts !important;
}

.ellipsis {
  display: -webkit-box;
  //default no. of lines
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.availabilityType {
  border-radius: 1rem;
  padding: 0.18rem 0.6rem;
  width: fit-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &--available {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  }

  &--onRequest {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }

  & > span {
    font-weight: 700;
    letter-spacing: 0.5px;
    font-size: 0.625rem;
    white-space: nowrap;
  }
}

.mandatory {
  margin-top: -2px;
  color: red;
}

.b2b {
  padding-inline: 0.5rem !important;
  display: flex;
  align-items: center;

  & > a {
    color: #fff;
    text-decoration: none;
    height: -moz-fit-content !important;
    height: fit-content !important;
    font-family: 'Mulish', sans-serif;
    font-size: 0.7rem;
    font-weight: 700 !important;
    line-height: 1.25rem;
    letter-spacing: 0.04em;
  }
}

.taxPlusOtherCharges {
  font-size: 0.7rem;
  font-weight: 600;

  line-height: 0.875rem;
  letter-spacing: 0em;
  color: #979797;
  text-align: right;
  width: 87%;
  display: block;
}

.btn-outline-primary {
  border-color: $primary-color !important;
}

.bookingStatus__action-btns {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  margin-block: 1rem;

  @include mobile {
    gap: 0.5rem;
    width: 80%;
    margin-inline: auto;
  }
}

.cancellationBtn {
  background: none;
  color: #bb9356;
  font-size: 0.9rem;
  border: none;
  padding: 0;
  border-bottom: 1px solid #bb9356;
  white-space: nowrap;
}

input {
  transition: 250ms ease-in-out;

  &::placeholder {
    letter-spacing: 1px;
    font-weight: 600;
  }

  &:not(:disabled):not(:read-only):is(:hover, :focus) {
    border-color: #785f3a !important;
    box-shadow: 1px 0 5px 5px #fff4e2de !important;
  }
}

select {
  transition: 250ms ease-in-out;

  &::placeholder {
    letter-spacing: 1px;
    font-weight: 600;
  }

  &:not(:disabled):not(:read-only):is(:hover, :focus) {
    border-color: #785f3a !important;
    box-shadow: 1px 0 5px 5px #fff4e2de !important;
  }
}

textarea {
  transition: 250ms ease-in-out;

  &:not(:disabled):not(:read-only):is(:hover, :focus) {
    border-color: #785f3a !important;
    box-shadow: 1px 0 5px 5px #fff4e2de !important;
  }
}

.modifyBtn-cont {
  align-self: flex-end;
  & > button {
    width: 3.25rem !important;
    height: 3.25rem !important;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .searchBtn {
    min-width: auto !important;
  }
}

input,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
strong,
a,
li,
ul,
td,
th {
  font-family: inherit !important;
}

button {
  border: none;
}

.viewOnMap {
  font-size: 0.9rem !important;
}

.carousel-btns-cont {
  display: flex;
  align-items: center;
  gap: 0.625rem;

  & > button {
    width: 3rem;
    height: 3rem;
    background: none;
    padding: 0;
    color: #fff;

    &:is(:hover, :focus) svg {
      color: $primary-color-200;
    }

    @media screen and (max-width: 31.25rem) {
      width: 2.2rem;
      height: 2.2rem;
    }

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
}

.home__section {
  width: 95%;
  margin-inline: auto;

  @media screen and (min-width: 78rem) {
    width: auto !important;
    margin-inline: 8rem;
  }

  @include tab {
    width: 100%;
    padding-inline: 2rem;
  }
}

.startingFrom {
  /* color: #575454; */
  color: $subtitle-texts;
  font-size: 0.7rem;
  font-weight: 600;
}

.inclAllTax {
  font-size: 0.7rem;
  /* color: #818181; */
  color: $subtitle-texts;
  display: block;
  text-transform: lowercase !important;
}

.fs_normal {
  font-weight: 400;
}

.fs_medium {
  font-weight: 500;
}

.fs_semibold {
  font-weight: 600;
}

.fs_bold {
  font-weight: 700;
}

.fs_extrabold {
  font-weight: 800;
}

.fs_black {
  font-weight: 900;
}

.color_blue {
  // color: $themecolorblue;
}

.color_green {
  // color: $themecolorgreen ;
  color: $primary-color;
}

.color_darkgreen {
  color: $themecolordarkgreen;
}

// fix div after fix height in serch result page

::ng-deep #fixeddiv {
  position: sticky !important;
  top: 20px !important;
}

.inputpointer {
  cursor: pointer;
}

/* .slick-slide img {
  display: inline-block !important;
} */

.fullwidth {
  padding: 0px;
}

.menucolor {
  background: #fff;
}

.footer__section {
  background: $primary-color;
}

nav.navbar.navbar-expand-sm.fullwidth.menucolor {
  height: 46px;
}

.whitecolor {
  color: $white;
  font-size: 80px;
}

.caboffer {
  color: $white;
  font: normal normal normal 30px/31px 'Mulish', sans-serif;
  letter-spacing: 0.95px;
  margin: 10px 0 0;
}

a.active {
  // color: $themecolorgreen !important;
  color: $primary-color !important;
}

a.deactive {
  // color: $themecolorblue !important;
  color: $primary-color !important;
}

.text-right {
  float: right;
}

.offerwhite {
  color: $white;
}

.mt-30 {
  margin-top: 30px;
}

::ng-deep .mt-20 {
  margin-top: 20px !important;
}

.bookNow {
  background: #fff !important;
  border: 1px solid $primary-color !important;
  border-radius: 0.25rem;
  color: $primary-color !important;

  &:is(:hover, :focus) {
    border: 1px solid #000 !important;
  }

  & > .loader {
    display: block;
    margin-left: 0 !important;
    height: 1.5625rem !important;
    width: 1.5625rem !important;
  }
}

.bgblue {
  // background-color: $themecolorblue !important;
  color: $white;
}

.footerbutton {
  background-color: #dedede;
  // color: $themecolorgreen;
  color: $primary-color;
  font-size: 12px;
}

.greycol {
  color: $greytext;
  font: normal normal 600 32px 'Mulish', sans-serif;
  letter-spacing: 1.13px;
  line-height: 1.5;
}

.formlabel {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

// checked checkbox color
.checked {
  accent-color: #f61b01;
}

// .p-inputtext {
//   width: 100%;
//   border: none;

//   border-bottom: 1px solid rgb(0 0 0 / 16%);
//   padding: 5px 10px;
//   outline: none;
//   border-radius: 0;
//   font: normal normal 500 14px 'Mulish';
//   color: #000000;
// }

.p-inputtext:enabled:focus {
  box-shadow: none;
  // border-color: $themecolorblue;
}

.labelradio {
  color: $greytext;
  font-size: 13px;
  font-weight: bold;
}

//   search page radio button

.labelsearchpage {
  color: $white;
  font-size: 13px;
  font-weight: bold;
}

.searchradiochecked {
  accent-color: $white;
}

//  end search page radio button

//   placeholder
::placeholder {
  color: $greytext;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
}

:-ms-input-placeholder {
  color: $greytext;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
}

::-ms-input-placeholder {
  color: $greytext;
  opacity: 1;
  font-size: 12px;
  font-weight: bold;
}

.btnleft {
  float: left;
}

.bordernone {
  border: none;
}

ul#myTab li {
  /* border-bottom: 1px solid #0000000f; */
  box-shadow: 9px 6px 14px #00000029;
  /* margin-bottom: 0px; */
}

.nav-tabs .nav-link {
  border-color: #ffffff !important;
}

.nav-tabs .nav-link.active {
  border-bottom: 1px solid $primary-color !important;
}

.formround form.form {
  padding: 20px 15px 0;
}

label.labelweight input {
  height: 15px;
  width: 15px;
}

.navbar-nav .nav-item {
  margin: 0;
  padding: 0;
}

div#mynavbar .navbar-nav .nav-link {
  height: fit-content !important;
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  font-weight: 700 !important;
  line-height: 1.25rem;
  letter-spacing: 0.04em;
  text-align: center;
}

div#mynavbar .navbar-nav .nav-link.active {
  background: #fff !important;
}

div#mynavbar .navbar-nav .nav-link img {
  margin-right: 5px;
}

div#mynavbar .navbar-nav .nav-link .badge {
  margin-left: 10px;
  font: normal normal 500 10px 'Mulish', sans-serif;
  letter-spacing: 0.21px;
  color: #ffffff;
}

div#mynavbar .navbar-nav .nav-link:hover {
  // color: #19458C;
  color: #000;
  // font-weight: bold;
}

div#mynavbar .navbar-nav .nav-item:first-child img {
  margin-right: 0px;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

#newsletter button.btn {
  height: 53px;
  width: 150px;
  font: normal normal 500 12px 'Mulish', sans-serif;
  letter-spacing: 0.35px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  color: #ffffff;
}

#newsletter button.btn:hover {
  //background: #19458C;
  // box-shadow: inset -10.5em 0 0 0 #19458c, inset 10.5em 0 0 0 #19458ce6;
  box-shadow: inset -10.5em 0 0 0 #000, inset 10.5em 0 0 0 #000;
}

// #reveal1 {
//   -webkit-transform: scale(0.9);
//   -moz-transform: scale(0.9);
//   -ms-transform: scale(0.9);
//   -o-transform: scale(0.9);
//   transform: scale(0.9);
//   -webkit-transition: all 1s ease-in-out;
//   -moz-transition: all 1s ease-in-out;
//   -ms-transition: all 1s ease-in-out;
//   -o-transition: all 1s ease-in-out;
//   transition: all 1s ease-in-out;
// }
#reveal1 {
  // -webkit-transform: scale(0.9);
  // -moz-transform: scale(0.9);
  // -ms-transform: scale(0.9);
  // -o-transform: scale(0.9);
  // transform: scale(0.9);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

#reveal1.visible {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.ecotravels_offer .slick-prev.slick-arrow {
  bottom: 0%;
  background: url(assets/icons/left.svg);
  position: absolute;
  z-index: 9;
  font-size: 0;
  padding: 17px 15px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 17px;
  width: 50px;
  height: 50px;
  left: -60px;
}

.ecotravels_offer .slick-next.slick-arrow {
  position: absolute;
  z-index: 9;
  bottom: 0%;
  font-size: 0;
  padding: 17px 15px;
  background: url(assets/icons/right.svg);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 17px;
  width: 50px;
  right: -60px;
  height: 50px;
}

.slick-slider .slick-arrow:before {
  display: none;
}

#animate_new {
  transition: transform 0.5s ease-out;
}

.zap {
  transform: scale(1.15, 0);
}

#animate_colorchange,
#animate_offerbg,
#animate_colorchange1,
#animate_colorchange2,
#animate_offerbg1 {
  transition: background-color 1s;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0rem;
}

// common banner

/*commonBanner*/
.cmn-bnr {
  background-size: cover;
  background-position: center;
  height: 225px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/*.cmn-bnr:after{ content: "";
     position: absolute;
     background: #19458cf5;
     height: 100%;
     width: 100%;left: 0;top:0;
 }*/

/* .cmn-bnr:before {
  content: "";
  position: absolute;
  background: rgb(34 76 145 / 72%);
  height: 318px;
  width: 400px;
  border-radius: 50%;
  animation: cmn-bnr2 2s infinite;
  top: 132px;
  z-index: 1;
  left: 0;
  background-size: 400px;
} */

@keyframes cmn-bnr2 {
  0% {
    box-shadow: 0 0 0 0px rgb(25 69 140 / 25%);
  }

  100% {
    box-shadow: 0 0 0 20px rgb(25 69 140 / 10%);
  }
}

.cmn-bnr h1 {
  font: normal normal 500 40px 'Mulish', sans-serif;
  letter-spacing: 1.13px;
  color: #ffffff;
}

li.breadcrumb-item a,
li.breadcrumb-item.active,
.breadcrumb-item + .breadcrumb-item::before {
  font: normal normal 500 18px/23px 'Mulish', sans-serif;
  letter-spacing: 0.54px;
  color: #ffffff;
  text-decoration: none;
  background: transparent;
}

.breadcrumb-item.active::before {
  float: left;
  padding-right: 0.5rem;
  color: #fff;
  content: '/';
}

.cmn-bnr h1 span {
  position: relative;
}

.cmn-bnr h1 span:before {
  content: '';
  position: absolute;
  background: rgb(34 76 145);
  height: 87px;
  width: 87px;
  border-radius: 50px;
  animation: cmn-bnr2 2s infinite;
  top: -23px;
  z-index: -1;
}

.cmn-bnr h1 span {
  position: relative;
  font-weight: 800;
  // color: #28A74D;
}

//  accordion

.accordion-item {
  background: rgb(255 255 255);
  padding: 20px;
  border-radius: $defaultRadius !important;
  border: $box-shadow-default !important;
  margin-bottom: 30px;
}

.accordion-item > .accordion-header {
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accordion-header button.accordion-button {
  font: normal normal 600 18px 'Mulish', sans-serif;
  letter-spacing: 0.48px;
  color: #19458c !important;
  box-shadow: none !important;
  background: transparent;
  padding: 0;
  border-radius: 21px;
  position: relative;
}

.accordion-header .accordion-button.collapsed {
  color: #000000 !important;
}

//  .accordion-button:not(.collapsed)::after {
//      width: auto;
//      height: auto;
//      background-image: none;
//      content: "-";
//      font-size: 48px;
//      transform: translate(-5px, -4px);
//      transform: rotate(
//  0deg
//  );
//  }

//  .accordion-button::after {
//      width: auto;
//      height: auto;
//      content: "+";
//      font-size: 40px;
//      background-image: none;
//      font-weight: 100;
//      color: #19458C;
//      transform: translateY(-4px);
//  }

.accordion-body,
.cmn-details {
  padding: 1rem 0rem 0.5rem;
  color: #000000;
  font: normal normal normal 16px 'Mulish', sans-serif;
  letter-spacing: 0px;
}

.accordion-body ol li,
.accordion-body ul li,
.accordion-body p {
  list-style: none;
  font: normal normal normal 16px 'Mulish', sans-serif;
  color: #000000;
  margin-bottom: 10px;
  position: relative;
}

.accordion-body ul li:before {
  content: '';
  position: absolute;
  background: url('./assets/images/privacy/done1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  left: -38px;
  height: 30px;
  width: 30px;
}

.cmn-details {
  position: relative;
}

.crm-details .accordion-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.accordionExample {
  width: 70%;

  @include tab {
    width: 80%;
  }

  @include mobile {
    width: 90%;
  }
}

#accordionExample {
  width: 70%;

  @include tab {
    width: 80%;
  }

  @include mobile {
    width: 90%;
  }
}

.searchbox-wrap {
  // display: flex;
  // height: 100px;
  height: auto;
  margin-top: 1%;
  // margin-left: auto;
  // margin-right: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #e7e6e6;
  // border-radius: 50px;
  // padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

// here we are starting signup/signin/forgot password
.auth-content {
  a,
  .btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .checkOuts.d-flex {
    background: #ffffff;
    padding: 30px 0;
    align-items: center;
    position: relative;
    margin-left: 30px;
    border-radius: 0.5rem;
    border: 1px solid $primary-color;
    // box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
    margin-bottom: 0px;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    @include mobile {
      margin-left: 0px;
    }
  }

  .login_btn img {
    margin-right: 10px;
  }

  .chec_dtl h3 {
    font-size: 28px;
    font-weight: 600;
    color: #9f9595;
    margin: 0;
    cursor: pointer;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }

  .checkact_dtl p {
    color: #6d6868;
    font-size: 14px;
    font-weight: normal;
  }

  .users_ic {
    position: absolute;
    left: -30px;
    top: 23px;
    background: $primary-color;
    height: 49px;
    width: 49px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      display: none;
    }
  }

  .chec_dtl {
    padding: 0 40px;
    width: 100%;
    @include mobile {
      padding: 0 20px;
    }
  }

  // .checkout_box {
  //   // background: #F1F1F1;
  //   // min-height: 100vh;
  //   // background: url(./assets/images/banner.jpg);
  //   /* padding: 30px 0;
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center; */
  // }

  // .checkout_box:before {
  //   // position: absolute;
  //   // content: '';
  //   // width: 100%;
  //   // height: 100%;
  //   // background: -webkit-linear-gradient(0deg, #061a38 20%, rgba(0, 0, 0, 0.0) 100%);
  //   // top: 0px;
  //   // right: 0px;
  // }

  .chec_dtl.active-auth h3 {
    color: #252525 !important;
  }

  .checkact_dtl {
    display: none;
  }

  .checkact_dtl.active-auth {
    display: block;
  }

  .login_ac {
    background: #ebf9fc;
    height: 60px;
    width: 181px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    flex-direction: column;
    border: 1px solid rgb(17 192 230 / 34%);
    color: #252525;
  }

  .login_ac a {
    color: #252525;
  }

  .checkOuts.d-flex.active-auth {
    height: auto;
    padding: 30px 0;
    align-items: flex-start;
  }

  .sign_ac {
    background: #11c0e6;
    height: 60px;
    width: 181px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    flex-direction: column;
    border: 1px solid #11c0e6;
    color: #fff;
    margin-left: 30px;
  }

  .sign_ac a {
    color: #ffffff;
  }

  .img_crt > img {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 20px;
  }

  .img_crt h4 {
    color: #252525;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  .stre_nm {
    margin-left: 20px;
  }

  .my_crt {
    background: #fff;
    padding: 30px;
  }

  .prce_item_crt p,
  .item_no_show span {
    color: #252525;
    font-size: 14px;
    font-weight: normal;
  }

  .prce_item_crt .sale_price {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
  }

  .img_crt {
    margin-bottom: 20px;
  }

  .crt_itm {
    margin-bottom: 10px;
  }

  .suggstin {
    background: #ebfcff;
    height: 44px;
    font-size: 14px;
    // font-style: italic;
    color: #6d6868;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chec_dtl.active-auth h3 {
    font: normal normal bold 24px 'Mulish', sans-serif;
    letter-spacing: 0.6px;
    color: #252525;
  }

  .chec_dtl h3 {
    font-size: 28px;
    font-weight: 600;
    color: #9f9595;
    margin: 0;
    cursor: pointer;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }

  .chec_dtl p {
    color: #252525;
    font-size: 14px;
  }

  .enqry_frm label {
    font: normal normal 500 15px 'Mulish', sans-serif;
    color: #3e3f3f;
    margin-bottom: 6px;
  }

  .enqry_frm input[type='text'],
  .enqry_frm input[type='number'],
  .enqry_frm input[type='email'],
  .enqry_frm input[type='password'] {
    height: 45px;
    padding: 4px 15px 4px 20px;
    font-weight: 500;
    width: 100% !important;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    margin-bottom: 20px;
    color: rgb(62 63 63 / 75%);
    font-size: 14px;
    box-shadow: none;
    border: 1px solid rgb(112 112 112 / 51%);
    border-radius: 4px;
  }

  .enqry_frm input:not([type='checkbox']):hover,
  .enqry_frm input:not([type='checkbox']):focus {
    background: #f8fdfe;
  }

  :focus {
    outline: 0;
  }

  .login_btn {
    // background: #2EA549;
    background: $primary-color;
    border-radius: 27px;
    color: #fff;
    height: 42px;
    width: 150px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }

  .login_btn:hover {
    box-shadow: inset -10.5em 0 0 0 #000, inset 10.5em 0 0 0 #000;
    color: #ffffff;
  }

  .sgn-in {
    font-size: 14px;
    color: #686868;
  }

  .loginField {
    padding: 0.9375rem !important;
    height: auto !important;
  }

  .sgn-in a {
    font-weight: 500;
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: 0.21px;
    // color: #2EA549;
    color: $primary-color;
  }

  .sgn-in img {
    height: 15px;
    margin-right: 10px;
  }

  .field-icon {
    position: absolute;
    top: 53%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgb(62 63 63 / 75%);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.ngx-slider {
  // margin: 27px 0 15px !important;

  .ngx-slider-pointer {
    // background-color: $themecolorblue !important;
    width: 20px !important;
    height: 20px !important;
    top: -7px !important;

    &:after {
      top: 6px !important;
      left: 6px !important;
    }

    &.ngx-slider-active:after {
      background-color: #fff !important;
    }

    &:hover:after {
      background-color: #fff !important;
    }
  }

  .ngx-slider-selection {
    // background-color: $themecolorblue !important;
    // border-radius: 4px !important;
  }

  .ngx-slider-tick.ngx-slider-selected {
    // background: $themecolorblue !important;
  }

  /*  .ngx-slider-bar {
    height: 6px !important;
    border-radius: 4px !important;
  } */
}

.sym-column-filter {
  width: 100%;
  // padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  height: 48px;
  border: 1px solid #ced4da;
  // -webkit-appearance: none;
  // appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pull-c-right {
  float: right;
}

.pull-c-left {
  float: left;
}

.font-icon {
  font-size: 12px;
}

.btn_signup {
  background: $primary-color 0% 0% no-repeat padding-box !important;
  border-radius: 30px !important;
  opacity: 1;
  padding: 5px 30px;
  text-align: center;
  font: normal normal 400 10px/18px 'Mulish, sans-serif';
  letter-spacing: 0px;
  color: #ffffff !important;
}

.btn_signup_font {
  font-size: 15px !important;
}

.btn_signup:hover {
  color: #fff;
}

.flight_top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  background: url(assets/images/banner.png), $primary-color;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #fff;

  // margin-top: 10px;
  @include mobile {
    height: 120px;
    padding-top: 35px;
  }
}

.flight_top p {
  font: normal normal 500 16px 'Mulish', sans-serif;
  letter-spacing: 0.27px;
  color: #ffffff;
}

.boder_2:before {
  content: '';
  position: absolute;
  width: 230px;
  height: 2px;
  background: #e9dddd8a;
  left: 32px;
  top: 12px;
}

.flight_top img {
  height: 20px;
}

.flight_top img.cr_fltop {
  margin: 5px 0 0;
  height: 16px;
}

/*/ width /*/
::-webkit-scrollbar {
  width: 5px;
}

/*/ Track /*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #fff;
  border-radius: 0px;
}

/*/ Handle /*/
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 20px;
  height: 50px;
}

input:focus-visible {
  outline: none;
}

// .la-ball-scale-multiple {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 32px;
//   height: 32px;
//   border-radius: 100%;
//   opacity: 0;
//   animation: none !important;
// }
.p-button {
  // background: $themecolorblue !important;
  // border: 1px solid $themecolorblue !important;
}

.p-datepicker table td > span {
  width: 30px !important;
  height: 30px !important;
}

.p-datepicker table td {
  padding: 0px !important;
}

#fixdiv {
  position: sticky !important;
  top: 110px !important;
}

#fixpricediv {
  position: sticky !important;
  top: 120px !important;
}

.dpBg {
  background-color: #ffffff !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.toast-success {
  background-color: #bb9356 !important;
  color: #2a2222 !important;
}

.toast-error {
  background-color: #a5bbc8 !important;
  color: #2a2222 !important;
}

.toast-info {
  background-color: #a18292 !important;
  color: #2a2222 !important;
}

.toast-warning {
  background-color: #4b5074 !important;
  color: #fff !important;
}

// button.p-element.p-ripple.p-datepicker-trigger.ng-tns-c72-2.p-button.p-component.p-button-icon-only.ng-star-inserted {
//   opacity: 0.0 !important;
//   z-index: 99999 !important;
//   width: 100px !important;
//   margin-left: -31px !important;
// }

// button.p-element.p-ripple.p-datepicker-trigger.ng-tns-c72-6.p-button.p-component.p-button-icon-only.ng-star-inserted{
//   opacity: 0.0 !important;
//   z-index: 99999 !important;
//   width: 100px !important;
//   margin-left: -31px !important;
// }

.calposition {
  top: -50px;
  position: relative;
}

.active-segment {
  background: $primary-color 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #19458c;
  border-color: #0d6efd;
}

.calButton {
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
  transition: 200ms ease-in-out;

  &:is(:hover, :focus) {
    background: $primary-color-200 !important;
  }
}

.reserved {
  background: #f61b0182 0% 0% no-repeat;
  font-size: 17px;
}

.unreserved {
  background: green;
  font-size: 17px;
}

.indicator {
  position: relative;
  top: 5px;
  padding-left: 10px;
}

.meals_cdetl {
  background: #fff;
  cursor: pointer;
  position: relative;
  // border: 1px solid rgba(112, 112, 112, 0.51);
  // border-radius: 4px;
  padding: 10px 20px !important;
  // font-size: 12px !important;
}

.border-b-c-danger {
  border-bottom: 1px solid red !important;
}

//view ticket button
.view_ticket {
  border: 1px solid $primary-color !important;
  background: none;
  outline: none;
  color: $primary-color !important;
  font-weight: 600 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem !important;
  // transition: 700ms ease-in-out;

  &:is(:hover, :focus) {
    // background: $primary-color !important;
    outline: none;
    border-color: #000 !important;
    color: #fff !important;
  }
}

// seat row number design
.seatrownumber {
  background-color: $primary-color;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: $white;
  padding: 1px;
}

.searchfixed {
  // display: flex;
  // height: 100px;
  height: auto;
  // margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  // border: 1px solid #E7E6E6;
  // border-radius: 50px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // color: $white;
}

.ngbcalander {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 50px;
}

.loader {
  border: 6px solid $primary-color;
  border-radius: 50%;
  border-top: 6px solid #ffffff;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin-inline: auto;
}

.mr-10 {
  margin-right: 10px;
}

// for line breaks in primeng autocomplete suggestion panel
.custom-suggestion {
  display: block;
  white-space: normal;
  overflow-wrap: break-word;
  line-height: 1;
}

.swal2-styled.swal2-confirm {
  background-color: $primary-color !important;
  color: #ffffff !important;
  border: none;
  transition: background-color 0.4s ease, box-shadow 0.4s ease;

  &:hover {
    background-color: $hover-color !important;
    color: #ffffff !important;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4) !important;
  }
}

.red_color {
  color: red;
  font-size: 15px;
}

.rtl-currency {
  display: flex !important;
  gap: 3.5px;
  align-items: baseline !important;
  justify-content: flex-end;
}

.rtl-total {
  display: inline-flex !important;
  align-items: baseline !important;
  gap: 5px;
  white-space: nowrap !important;
}

.flex-unset-price {
  justify-content: unset !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ecotravels_offer .slick-arrow {
    display: none !important;
  }

  .fsize[_ngcontent-ehg-c139] {
    font-size: 10px;
  }

  .flight-list .px-4.d-flex,
  .flight-list .px-2.total_price.d-flex {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .col-md-3.ftr_logo img {
    width: 95%;
  }

  .returnDate {
    width: 16.66666667%;
  }

  .fsize[_ngcontent-tkw-c139] {
    font-size: 10px;
  }

  .searchbox-wrap .col-md-2.mt-20 {
    width: 100%;
    margin: -14px auto 11px;
  }

  section.chart .filter h5 {
    font: normal normal 500 14px;
  }

  section.chart .filter h5 img {
    height: 11px;
  }

  section.chart .filter p {
    font: normal normal 400 10px 'Mulish', sans-serif !important ;
  }

  section.chart .card-1 .sec-1 .btn-toggle,
  section.chart .price .heading > b {
    font: normal normal 700 11px 'Mulish', sans-serif;
  }

  section.chart .flight-list .header .d-flex {
    flex-wrap: wrap;
  }

  section.chart .flight-list h4 {
    font: normal normal 900 12px 'Mulish', sans-serif !important;
  }

  section.chart .flight-list .list-bottom a.btn.btn-toggle {
    font-size: 0.8rem;
  }

  .domesticRoundF {
    overflow: auto;
  }

  .domesticRoundF .row.flight-list {
    flex-wrap: nowrap;
  }

  .domesticRoundF .row.flight-list .col-md-5 {
    width: 60%;
  }

  .searchbox-wrap {
    border-radius: 20px !important;
  }

  .footerArrow[_ngcontent-ntw-c220] {
    width: 81px;
  }
}

@media only screen and (max-width: 767px) {
  .searchbox-wrap.py-1 {
    border-radius: 20px;
    padding: 20px !important;
  }

  .nav-link.tabscolor {
    padding: 0.6rem 0.5rem !important;
  }

  .greycol {
    font: normal normal 600 28px 'Mulish', sans-serif;
    line-height: 1.1;
  }

  nav.navbar.navbar-expand-sm.fullwidth.menucolor {
    padding-top: 0.2rem;
  }

  .boder_2:before {
    width: 99px;
  }

  .auth-content .login_btn {
    width: auto !important;
    padding: 0 15px !important;
  }

  span.pull-c-right.color_blue.fs_semibold {
    float: left;
  }

  section.chart .row.mobile_block {
    margin: 0 auto;
  }

  .flight-list .header {
    overflow: auto;
  }

  .ecotravels_offer .slick-arrow {
    display: none !important;
  }

  .testimonial-slider .slick-list {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 812px) and (orientation: landscape) {
  .ecotravels_offer_big .padding-right img {
    min-height: 389px !important;
  }

  .ecotravels_offer button.slick-arrow {
    display: none !important;
  }

  .offers_newsletter:before {
    right: 0 !important;
  }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
