@keyframes fullBar {
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.is-visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}

/* Hidden state */
.fade-in {
  opacity: 0;
  transform: translateY(20px); /* Slight downward offset */
  transition: none; /* Prevent animation during initial load */
}

