@import './_variables.scss';
@import '../assets/variables/responsive_variables.scss';

// fonts
@font-face {
  font-family: 'DroidArabicKufi';
  src: url('/assets/fonts/DroidArabicKufi-Regular.woff2') format('woff2'),
    url('/assets/fonts/DroidArabicKufi-Regular.woff') format('woff'),
    url('/assets/fonts/DroidArabicKufi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body.rtl {
  font-family: 'DroidArabicKufi', sans-serif !important;
  direction: rtl;

  .form-control.is-invalid,
  .contact-form .form-control.is-invalid {
    background-position: left calc(0.375em + 0.1875rem) center !important;
  }

  .top__nav {
    justify-content: flex-start !important;
    padding-left: 0;
    padding-right: 2rem;

    @include mobile {
      padding-left: 0.5rem;
      padding-right: 0;
    }

    &-links {
      justify-content: flex-start !important;
    }
  }

  .tabs-cont {
    padding-right: 0;
  }

  .availabilityType {
    margin-left: unset;
    margin-right: auto;
  }

  .room__info-header__total-and-action > button {
    margin-left: unset !important;
    margin-right: auto;
  }

  .user__account .dropdown-content {
    right: 0;
    left: unset;

    @include mobile {
      right: 0;
      left: unset !important;
    }
  }

  .miscellaneous {
    order: 0;
    flex-direction: row;
    align-items: flex-start;
  }

  //change the whole navbar direction
  .navbar-wajed {
    direction: ltr;
  }

  //change the links direction to rtl so logo will stay on the right side
  .navbar__links {
    direction: rtl;
  }

  .serviceSubType {
    margin-left: 0;
    margin-right: 0.3rem;
  }

  // .user__account .dropdown-content {
  //   left: unset !important;
  //   right: unset !important;
  // }

  .resetPassForm {
    .resetPassMessageIcon {
      border-radius: 0 0.25rem 0.25rem 0 !important;
    }

    .resetPassField {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }

    .sgn-in {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      & > img {
        transform: rotate(180deg);
      }
    }
  }

  /* & section.price, */
  /* .description p, */
  /* .form-select, */
  /* .remarks { */
  /*   direction: ltr !important; */
  /* } */

  .form-control {
    text-align: right !important;
  }

  .hero__section .dropdown-menu.show {
    left: -130% !important;
  }

  .p-datepicker-prev,
  .p-datepicker-next,
  .ngb-dp-arrow,
  .p-carousel-next,
  .p-carousel-prev,
  .carousel-control-prev,
  .carousel-control-next,
  .owl-prev,
  .owl-next,
  .p-galleria-thumbnail-prev,
  .p-galleria-thumbnail-next {
    transform: scaleX(-1) !important;
  }

  .cal-next-arrow-button .p-datepicker-next {
    transform: scaleX(1) !important;
  }

  .dropdown-content {
    left: unset !important;
    right: 0 !important;
  }

  // rtl for the arrow and airplane line png in flight search result cards
  .mx-auto,
  .arrowimg {
    transform: scaleX(-1) !important;
  }

  .arrowimg {
    margin-left: unset;
    margin-right: 25px;
  }

  section.chart .flight-list h4 {
    text-align: right !important;
  }

  .p-datepicker-title {
    display: flex;
  }

  .custom-galleria.p-galleria {
    border-top-right-radius: 0.645rem;
    border-top-left-radius: 0;

    @include tab {
      border-top-right-radius: 0.645rem;
      border-top-left-radius: 0.645rem;
    }
  }

  .custom-date-picker .p-datepicker-next {
    transform: scaleX(1) !important;
    box-shadow: none !important;
    outline: none !important;
    transition: none !important;
  }

  .custom-date-picker .p-datepicker-prev {
    box-shadow: none !important;
    outline: none !important;
    transition: none !important;
  }

  .custom-date-picker .p-calendar-w-btn .p-inputtext {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  .custom-date-picker .p-calendar-w-btn .p-datepicker-trigger {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-top-color: rgb(168, 168, 168) !important;
    border-right-color: transparent !important;
    border-bottom-color: rgb(168, 168, 168) !important;
    border-left-color: rgb(168, 168, 168) !important;

    &:hover,
    &:focus {
      border-top-color: rgb(168, 168, 168) !important;
      border-right-color: transparent !important;
      border-bottom-color: rgb(168, 168, 168) !important;
      border-left-color: rgb(168, 168, 168) !important;
    }
  }

  .checkIn_date .p-calendar .p-inputtext {
    border-color: $border-color !important;
    border-radius: 0 0.25rem 0.25rem 0 !important;
    border-left-color: transparent !important;
  }

  .checkOut_date .p-calendar .p-inputtext {
    border-color: $border-color !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    border-left-color: $border-color !important;
  }

  .shopping__link {
    text-align: right !important;
  }

  .shopping__dropdown-content {
    left: unset !important;
    right: 0 !important;
  }

  .decrease__btn {
    border-radius: 0 0.25rem 0.25rem 0;
    border-left: none !important;
    border-right: 1px solid $primary-color !important;
  }

  .increase__btn {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: none !important;
    border-left: 1px solid $primary-color !important;
  }

  // .hotel-search-panel-hotel .checkIn__input {
  //   border-left: none !important;
  // }

  // .checkIn__input {
  //   border-radius: 0 0.25rem 0.25rem 0 !important;
  // }

  // .hotel-search-panel-hotel .checkOut__input {
  //   border-left-width: 1px !important;
  // }

  .calendar-btn {
    border-radius: 0.25rem 0 0 0.25rem !important;
    border-right: 1px !important;
    border-left: none !important;
  }

  .ngb-datepicker-navigation {
    writing-mode: rl;
  }

  .sidebar {
    &__items {
      & > .nav-item,
      .sidebar__shopping {
        flex-direction: row-reverse;
      }
    }
  }

  .users_ic {
    left: 0 !important;
    right: -30px !important;
  }

  .rtlAcc,
  .chec_dtl {
    & > h3 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > img {
        height: 15px;
      }
    }
    & > p {
      text-align: right !important;
    }
  }

  .modifyBtn-cont {
    & > button {
      @include mobile {
        margin-left: unset !important;
        margin-right: auto;
      }
    }
  }

  .offers__email {
    & > input {
      border-radius: 0 0.3rem 0.3rem 0 !important;
    }

    & > button {
      border-radius: 0.3rem 0 0 0.3rem !important;
    }

    .solidButton {
      border-radius: 0.3rem 0 0 0.3rem !important;
    }
  }

  .web-buttons {
    & > a > i {
      transform: scaleX(-1);
    }
  }

  .account {
    .btn_grp {
      gap: 0.5rem !important;
    }

    .card_ticket .flight img {
      margin-left: 20px !important;
      margin-right: 0 !important;
    }
  }

  .checkOuts {
    margin-left: 0 !important;
    margin-right: 1.875rem !important;

    @media screen and (max-width: 767px) {
      margin-right: 0 !important;
    }
  }

  .form-control.is-invalid {
    padding-left: calc(1.5em + 0.75rem) !important;
    padding-right: 0.75rem !important;

    .passengerMobileCountry {
      padding-right: 0 !important;
    }
  }

  .seatInfo {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    & > p {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      padding-top: 0 !important;

      & > input {
        padding: 0 !important;
      }
    }
  }
  .pass_form .form-control {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }
  .calButton {
    border-radius: 0.25rem 0 0 0.25rem !important;
    border: none !important;
  }

  .passenger_info,
  .passenger {
    & .calButton {
      border: 1px solid #bb9356 !important;
    }
  }

  .hotel-search-panel-hotel .calendar_custom_styles .calButton {
    border: 1px solid #bb9356 !important;
  }

  // for the filters in flightsearch.component.ts
  .p-scrollpanel-content {
    width: auto;
    padding: 0;
  }

  .filter-by-text-container > p {
    right: 3% !important;
  }

  .whyBookUs__typo {
    align-items: flex-start;

    @include tab {
      align-items: center;
    }
  }

  .hotel-name {
    & > h5,
    & > p {
      padding-right: 0 !important;
    }
  }

  .hotel-discount-capsule > p {
    direction: ltr !important;
  }

  .acc-type > ul {
    padding-right: 0 !important;
  }

  .why-book-with-us-title > p {
    padding-right: 1rem;
    padding-left: 0 !important;
  }

  .viewOnMap {
    font-size: 0.7rem !important;
  }

  .field-icon {
    left: 1.25rem !important;
    right: unset !important;
  }

  .heading-container > div {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    padding-right: 0.5rem;
  }

  .heading-container > div > span:nth-child(2),
  .mobile-number-container,
  .comment-container {
    padding-left: 0 !important;
  }

  .mobile-number-container,
  .comment-container {
    padding-right: 0.5rem !important;
  }

  .mobile-number-container > div > span:nth-child(1) {
    transform: scale(-1) rotate(90deg);
  }

  .mobile-number-container {
    .contactNumber {
      padding-right: 0.5rem !important;
      padding-left: unset !important;
    }
  }

  .amenities .header > h5 {
    padding-right: 1rem;
  }

  .backBtn {
    display: flex !important;
    gap: 0.3rem;
  }

  .reverseBtn {
    @media screen and (max-width: 39.375rem) {
      right: 50%;

      transform: translateX(50%);
      left: 0;
    }
  }

  .responsive-buttons > .buttons_group {
    & button > i {
      transform: scaleX(-1);
    }
  }

  .info_heding i {
    transform: scaleX(-1) !important;
  }

  .booking-flight h3,
  .account .chec_dtl h3 {
    text-align: right !important;
  }

  .passenger_info .pi-chevron-right,
  .details-cont .pi-chevron-right,
  .booking_main_sec .pi-chevron-right {
    transform: scale(-1) !important;
  }

  .account .radio_btn .form-check {
    margin-right: 0 !important;
    margin-left: 2rem;
  }

  .account .pas_id p {
    text-align: right !important;
    margin-right: 8px;
  }

  .flight-gender-cont {
    margin-left: 1rem;
    margin-right: 0;
  }

  .pull-c-right {
    float: left;
  }

  .pi-chevron-down {
    transform: scale(1) !important;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    transform: scaleX(1);
  }

  .p-scrollpanel .p-scrollpanel-bar {
    display: none !important;
  }

  .passenger_info .p-scrollpanel-content {
    padding: 0 !important;
  }

  .searchBtn-cont {
    justify-content: flex-end !important;
    & > * {
      width: fit-content !important;
    }
  }

  .brandInputCont {
    position: relative;

    & > span {
      right: 0;
      left: unset !important;
    }
  }

  .gender__cont {
    margin-right: 0;
    margin-left: 1rem;
  }

  .details-cont .fa-arrow-right,
  .fa-arrow-left {
    transform: scaleX(-1);
  }

  & .plus__btn {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }

  & .minus__btn {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }

  .searchBtn-cont {
    justify-content: flex-start;
  }

  .visa-form-cont {
    .p-calendar-w-btn .p-inputtext {
      border-radius: 0 0.25rem 0.25rem 0 !important;
      border-right: 1px solid !important;
      border-left: none !important;
    }

    .p-button {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }
  }

  .carousel-btns-cont {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .offers__text,
  .links > ul,
  .best-deal-reviews,
  .copyright > span {
    text-align: right !important;
  }

  .links > ul {
    @include tab {
      text-align: center;
    }
  }

  // primeng's carousel
  .p-carousel-items-container {
    flex-direction: row-reverse !important;
  }

  .p-dropdown .p-dropdown-clear-icon {
    right: unset !important;
    left: 3rem !important;
  }

  .b2bLoginSection {
    &__typography__text {
      & > h1,
      & > h3 {
        line-height: 180%;

        @include mobile {
          line-height: normal !important;
        }
      }
    }
  }

  .callout-content {
    padding-right: 0 !important;
    padding-left: 40px !important;
  }

  .callout-content ul li {
    margin-right: 0 !important;
    margin-left: 45px;
  }
  .contactNumber {
    direction: ltr;
    display: flex !important;
    gap: 0.4rem;
    flex-direction: row-reverse;
  }

  .cmn-details {
    .accordion-button::after {
      margin-left: unset !important;
      margin-right: auto !important;
    }
  }

  .fareSummary_flight__heading {
    border-radius: 0;
    border-top-right-radius: 0.3125rem;

    &--empty {
      border-radius: 0;
      border-top-left-radius: 0.3125rem;
    }
  }

  .summary {
    & tr {
      vertical-align: middle;
      margin-bottom: 0.625rem;
    }

    td {
      text-align: right !important;
      border-bottom: none;

      p {
        font-size: 13px;
      }

      &:last-child {
        text-align: left !important;
      }
    }
  }

  .booking__status__table {
    tbody tr td {
      &:last-child {
        text-align: left !important;
      }
    }
  }

  .room__info__price {
    text-align: left !important;
  }

  .flight__booking {
    position: relative;

    & .close-btn-flight {
      right: unset !important;
      left: 0.5rem !important;
    }
  }

  .best-selling-packages .item {
    margin: 0.5rem 0.1rem 0.5rem 0.5rem !important;
  }

  .testimonial {
    .item {
      margin: 0.5rem 0.1rem 0.5rem 0.5rem !important;
    }
  }

  .p-toast .p-toast-message .p-toast-message-content {
    gap: 1rem;
  }

  .isMandatoryMsg {
    right: unset !important;
    left: 0.5rem !important;
  }

  .icon-calendar {
    .p-calendar .p-datepicker-trigger {
      border-left-color: #aaaaaa !important;
      border-right-color: transparent !important;

      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }

    .p-calendar-w-btn .p-inputtext {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .mobileAndCountryCode {
    .countryCode {
      border-right: none !important;
      border-left: 1px solid #79747e24 !important;
    }
  }

  .hotel-grid-view-container .grid-view-details .hotel-name {
    text-align: right !important;
  }

  /* .taxPlusOtherCharges { */
  /*   text-align: left !important; */
  /* } */

  ::ng-deep .flight__booking .solidButton {
    margin-left: unset;
    margin-right: auto !important;
  }

  .rootContFlight section.chart .flight-list h5:not(.layover),
  .rootContFlight section.chart .flight-list p {
    text-align: right !important;
  }

  .rootContFlight .addDisplayBlock {
    & ul {
      padding: 0;
    }
  }

  #cancellationPolicies {
    & button {
      margin-right: auto !important;
      margin-left: 0 !important;
    }
  }

  .rootContFlight .dropdown-fare-rules {
    & li {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .rootContFlight div .col-md-3.custom-tooltip {
    & > p {
      text-align: center !important;
    }
  }

  .postTicketing .table tbody tr td {
    text-align: right !important;
  }

  .newsletter {
    &__email {
      & > input {
        border-radius: 0 0.3rem 0.3rem 0 !important;
      }
    }

    .solidButton {
      border-radius: 0.3rem 0 0 0.3rem;
    }

    &__text {
      text-align: right !important; // for aligning texts both in english and arabic
    }
  }

  .p-autocomplete .p-autocomplete-loader {
    margin-left: 1rem !important;
    margin-right: unset !important;
    left: 1.2rem !important;
    right: unset !important;
  }

  .login_use_nm {
    img {
      margin-left: 0.3rem;
    }
  }

  .payments__email {
    text-align: right !important;
  }

  .hotel-price-and-discount-container {
    align-items: flex-start !important;

    .availabilityType {
      margin-left: unset;
      margin-right: unset !important;
    }
  }

  .hotel-description-and-amenities-tab-details-container {
    .hotel-description-tab-details-container {
      .description-content {
        & .description {
          text-align: right !important;
        }
      }
    }
  }

  .filtersHeader .p-panel-icons-end {
    margin-left: unset;
    margin-right: auto !important;
  }

  .rtl-currency {
    flex-direction: row-reverse !important;
    justify-content: unset;
    @media screen and (max-width: 28.125rem) {
      justify-content: flex-end !important;
    }
  }

  .flex-unset-price {
    justify-content: flex-end !important;
  }

  .details-cont .passengerMobileCountry {
    padding-right: 0.75rem !important;
    padding-left: 0 !important;
  }

  .details-cont .mobileInputClass {
    padding-right: 0.75rem !important;
    padding-left: 0 !important;
  }

  .accordion-body ul li:before {
    right: -38px;
    left: 0;
    transform: rotateY(180deg);
  }

  .accordion-header button.accordion-button {
    text-align: right;
  }

  .cmn-details ol {
    padding-left: 0;
    padding-right: 1rem;
    @media screen and (max-width: 25rem) {
      padding-right: 0.4rem;
    }
  }

  .bg-Image-plane:before {
    content: '';
    right: 100px;
    left: 0;

    @media screen and (max-width: 37.5rem) {
      right: -6.25rem;
    }
  }
}

body.rtl
  .hotel-card-container
  .hotel-card-text-container
  .hotel-name-and-price-container
  .hotel-price-and-discount-container
  .hotel-price-details {
  align-items: start !important;
  margin-left: auto;
}

@media screen and (max-width: 28.125rem) {
  body.rtl {
    .dropdown-menu.show {
      .carousel-btns-cont {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        & > button {
          width: 3.125rem;
          height: 3.125rem;
          background: none;
          padding: 0;

          & > svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      font-size: 0.8rem !important;
    }

    .room__subTypes__room-select {
      &__price {
        flex-direction: column-reverse;
        align-items: flex-start !important;
        & > p {
          align-items: unset !important;
        }
        .cancellationTag {
          margin-top: 0.25rem;
          text-align: right;
        }

        .currency {
          margin-top: 0.5rem;
          text-align: right;
        }
      }
    }

    .room__info {
      &__nameNdesc {
        flex-direction: column;

        & > div {
          width: 100%;
        }
      }

      &__price {
        margin-top: 0.8rem;
        .availabilityType {
          margin-left: unset !important;
          margin-right: unset !important;
        }

        text-align: right !important;
      }
    }

    .room__info-header__total-and-action {
      align-self: flex-start !important;
    }
  }
}

@media screen and (min-width: 18.75em) and (max-width: 47.9375em) {
  body.rtl {
    .modifyBtn-cont {
      & > button {
        margin-right: auto;
        margin-left: unset;
      }
    }

    .callout-content ul li {
      margin-left: 40px !important;
    }
  }
}

@media screen and (max-width: 68.125rem) {
  body.rtl {
    .hotel-search-panel-hotel .hotel-search-pane-hotel__form {
      .checkOut__input {
        border-radius: 0.25rem 0 0 0.25rem !important;
        border-left: 1px solid $primary-color !important;
      }
    }
  }
}
